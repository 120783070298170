<template>
  <div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <router-link v-if="$can('create_banner')" :to="{name: 'Add Pop Up Beli Emas'}" class="btn btn-primary"><i class="fa fa-plus"></i> Create Pop Up</router-link>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="image-slot" slot-scope="prop">
          <div>
            <img :src="prop.rowData.image_url" alt="" srcset="" style="width:50px; height:100px">
          </div>
        </template>
        <template slot="is_promotion-slot" slot-scope="prop">
          <span>{{ prop.rowData.is_promotion === true ? 'Yes' : 'No' }}</span>
        </template>
        <template slot="article_url" slot-scope="prop">
          <span v-if="prop.rowData.article_url === null"> - </span>
          <a v-else :href="prop.rowData.article_url">To this Link</a>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button v-if="$can('update_banner')"  class="btn btn-info" @click="onAction('edit-item', prop.rowData)">Edit</button>&nbsp;
            <button v-if="$can('delete_banner')"  class="btn btn-danger" @click="onAction('delete-item', prop.rowData)"><i class="fa fa-trash-o"></i></button>
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        filterText: '',
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `popup`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        fields: [
          {
            name: 'is_promotion-slot',
            sortField: 'status',
            title: 'Active'
          },
          {
            name: 'image-slot',
            titleClass: 'left aligned',
            dataClass: 'center aligned',
            title: 'Image',
          },
          {
            name: 'article_url',
            title: 'Link'
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            width: '20%',
          }
        ],
        moreParams: {},
      }
    },
    methods: {
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'edit-item') {
          this.$router.push({name: 'Edit Pop Up Beli Emas', params: { id: data.hashed_id}})
        }else{
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Pop Up Beli Emas!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {

                this.$http.delete(`popup/` + data.hashed_id)
                .then(() => {
                  this.$swal.fire(
                    'Deleted!',
                    'Pop Up Beli Emas has been deleted.',
                    'success'
                  ).then(() => {
                    location.reload();
                  })
                }).catch((error) => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                'Cancelled',
                'Pop Up Beli Emas was successfully canceled for deletion!',
                'error'
              )
            }
          })
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
