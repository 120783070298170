<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                <i class="icon-menu mr-1"></i>Data Table Pop Up Beli Emas
            </b-card-header>
            <b-card-body>
                <gold-buy></gold-buy>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import goldBuy from './Table'
export default {
    name: 'popup-gold-buy',
    components: {
        goldBuy,
    },
}
</script>
